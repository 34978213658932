import { createRouter, createWebHashHistory } from "vue-router";

import { nextTick } from "vue";

let routes = [
  {
    path: "/",
    name: "gfmain",
    meta: { title: "GameFundle Daily" },
    component: () =>
        import(/* webpackChunkName: "crosscase" */ "@/views/GFMainPage"),
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: { title: "GameFundle Privacy Policy" },
    component: () =>
        import(/* webpackChunkName: "crosscase" */ "@/views/PrivacyGDPR"),
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "Page Not Found" },
    component: () => import(/* webpackChunkName: "gamelist" */ "@/views/404"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "404" },
  },
  {
    path: "/:pathMatch(.*)",
    redirect: { name: "404" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const DEFAULT_TITLE = "Mythos Games by Glentopher";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
